import Axios from "axios";
import { id } from "date-fns/locale";
import { language } from "../../src/helpers/Utils";
const base_url = process.env.REACT_APP_API_URL;
const org_instance = process.env.REACT_APP_ORG_INSTANCE_ID;

const defaultOptions = {
  headers: {
    "X-Authorization": "Bearer " + localStorage.getItem("token"),
    "post-id": localStorage.getItem("postId"),
    "session-id": localStorage.getItem("session_id"),
    "service-instance-id": localStorage.getItem("org_instance_id"),
    region: "uk",
    organization: "eln",
    "Content-Type": "application/json",
    "Accept-Language": language()
  }
};
export const instance = Axios.create(defaultOptions);

const service_instanceID = localStorage.getItem("org_instance_id");
export const ORG_USER_ADDPHNNUM_VERIFY = "/Org/User/AddPhoneNbr/Verify/";
export const ORG_USER_ADDRESS_CREATE = "/Org/User/Address/Create/";
export const ORG_USER_ADDRESS_UPDATE = "/Org/User/AddressInfo/Update/";
export const ORG_USER_EMAIL_ADD = "/Org/User/Email/Add/";
export const ORG_USER_GET_USER_DETAIL = "/Org/User/GetUserDetail/";
export const GET_USER_LIST = "/Org/User/ListFetch";
export const ORG_USER_PERSONAL_INFO_UPDATE = "/Org/User/PersonalInfo/Update/";
export const ORG_USER_PHNNUM_ADD = "/Org/User/PhoneNumber/Add/";
export const ORG_USER_PHNNUM_UPDATE = "/Org/User/PhoneNumber/Update/";
export const ORG_USER_PRIVATE_SIGNUP = "/Org/User/PrivateSignup/";
export const ORG_USER_PROFILE_IMG_ADD = "/Org/User/ProfileImg/Add/?id=";
export const ORG_USER_PROFILE_IMG_ADD_WITHOUT_ID = "/Org/User/ProfileImg/Add/";
export const ORG_USER_REMOVE = "/Org/User/Remove/";
export const ORG_AUTH_CHANGE_PASSWORD = "/Org/Auth/ChangePassword/";
export const GET_COUNTRY = "/county/?country-id=";
export const GET_STATE = "/state/?page_size=1000&country-id=";
export const GET_CITY = "/city/?page_size=1000&country-id=";
export const GET_COUNTRY_BY_PAGE = "/country/?page_size=1000";
export const ORG_AUTH_VERIFICATION_INPROGRESS =
  "/Org/Auth/VerificationInProgress/";
export const ORG_AUTH_RESEND_OTP = "/Org/Auth/ResendOTP/";
export const ORG_USER_USER_DETAIL_LIST = "/Org/User/UserDetail/List/";
export const ORG_AUTH_RESEND_VERIFICATION_EMAIL =
  "/Org/Auth/ResendVerificationEmail/";

// student scholarship
export const STU_EDUCATION_DETAIL = "/Student/EducationDetails/";
export const STU_FAMILY_DETAIL = "/Student/FamilyDetails/";
export const STU_PROFILE_DETAIL = "/Student/ProfileDetails/";
export const STU_PROPERTY_DETAIL = "/Student/PropertyDetails/";
export const STU_SCHOLARSHIP = "/Student/Schlorship/";
export const STU_UPDATE_PROFILE_DETAIL =
  "/Student/ProfileDetails/update_profile_details/";

export const STU_PROFILE_DETAIL_USER =
  "/Student/ProfileDetails/get_user_profile_details/?user=";
export const STU_EDUCATION_DETAIL_USER =
  "/Student/EducationDetails/get_education_details/?user=";
export const STU_FAMILY_DETAIL_USER =
  "/Student/FamilyDetails/get_family_details/?user=";
export const STU_PROPERTY_DETAIL_USER =
  "/Student/PropertyDetails/get_property_details/?user=";
export const STU_SCOLARSHIP_DETAIL_USER =
  "/Student/Schlorship/get_scholarship_details/?user=";

//Entity
export const ENTITY_PERSON_ADD = "/Entity/Person/Add/";
export const ENTITY_PERSON_EDUCATION_ADD = "/Entity/Person/Education/Add";
export const ENTITY_PERSON_FAMILY_ADD = "/Entity/Person/FamilyDetails/Add";
export const ENTITY_PERSON_OCCUPATION_ADD = "/Entity/Person/Occupation/Add";
export const ENTITY_PERSON_ASSET_ADD = "/Entity/Person/Asset/Add";
export const ENTITY_PERSON_CONTACTINFO_ADD = "/Entity/Person/ContactInfo/Add";
export const ENTITY_PERSON_LIST = "Entity/Person/List/";

export const ENTITY_PERSONAL_INFO_UPDATE =
  "/Entity/Person/PersonalInfo/Update?user=";
export const ENTITY_PERSON_EDUCATION_UPDATE = "/Entity/Person/Education/Update";
export const ENTITY_PERSON_FAMILY_UPDATE =
  "/Entity/Person/FamilyDetails/Update";
export const ENTITY_PERSON_OCCUPATION_UPDATE =
  "/Entity/Person/Occupation/Update";
export const ENTITY_PERSON_ASSET_UPDATE = "/Entity/Person/Asset/Update";
export const ENTITY_PERSON_CONTACTINFO_UPDATE =
  "/Entity/Person/ContactInfo/Update?id=";

export const ENTITY_PERSONAL_LIST = "Entity/Person/List/";
export const ENTITY_PERSON_EDUCATION_LIST = "/Entity/Person/Education/List";
export const ENTITY_PERSON_FAMILY_LIST = "/Entity/Person/FamilyDetails/List";
// export const ENTITY_PERSON_OCCUPATION_LIST = "/Entity/Person/Occupation/List"
export const ENTITY_PERSON_ASSET_LIST = "/Entity/Person/Asset/List";
export const ENTITY_PERSON_CONTACTINFO_LIST = "/Entity/Person/ContactInfo/List";

export const ENTITY_PERSONAL_INFO_LIST_BY_ID =
  "/Entity/Person/PersonalInfo/Record/Get";
export const ENTITY_PERSON_EDUCATION_LIST_BY_ID =
  "/Entity/Person/Education/Record/Get";
export const ENTITY_PERSON_FAMILY_LIST_BY_ID =
  "/Entity/Person/FamilyDetails/Record/Get";
// export const ENTITY_PERSON_OCCUPATION_LIST_BY_ID = "/Entity/Person/Occupation/Record/Get"
export const ENTITY_PERSON_ASSET_LIST_BY_ID = "/Entity/Person/Asset/Record/Get";
export const ENTITY_PERSON_CONTACTINFO_LIST_BY_ID =
  "/Entity/Person/ContactInfo/Record/Get";

export const ORG_ROLES_LIST = "/Org/Roles/List/";
export const ORG_POST_LIST = "/Org/Posts/List/";
export const ORG_ROLES_SERVICE_INSTANCE_LIST =
  "/Org/Roles/Service/Instance/List/";

export function createPerson() {
  return Axios.post(ENTITY_PERSON_ADD);
}
export function createEduDetail(value, userId) {
  return Axios.post(ENTITY_PERSON_EDUCATION_ADD, {
    user: userId,
    education: [value]
  });
}
export function createFamilyDetail(values, userId) {
  return Axios.post(ENTITY_PERSON_FAMILY_ADD, {
    user: userId,
    family_details: [values]
  });
}

export function createOccupationDetail(values, userId) {
  return Axios.post(ENTITY_PERSON_OCCUPATION_ADD, {
    user: userId,
    occupation: [values]
  });
}
export function createAssetDetail(values, userId) {
  return Axios.post(ENTITY_PERSON_ASSET_ADD, { user: userId, asset: [values] });
}
export function createContactDetail() {
  return Axios.post(ENTITY_PERSON_CONTACTINFO_ADD);
}

export function updatePerson(userId, values) {
  return Axios.patch(ENTITY_PERSONAL_INFO_UPDATE + userId, values);
}
export function updateEduDetail(value, userId) {
  return Axios.patch(ENTITY_PERSON_EDUCATION_UPDATE, {
    user: userId,
    education: [value]
  });
}
// export function updateFamilyDetail() {
//   return Axios.patch(ENTITY_PERSON_FAMILY_UPDATE + userId );
// }

export function updateFamilyDetail(values, userId) {
  return Axios.patch(ENTITY_PERSON_FAMILY_UPDATE, {
    user: userId,
    family_details: [values]
  });
}

export function updateOccupationDetail(values, userId) {
  return Axios.patch(ENTITY_PERSON_OCCUPATION_UPDATE, {
    user: userId,
    occupation: [values]
  });
}
export function updateAssetDetail(values, userId) {
  return Axios.patch(ENTITY_PERSON_ASSET_UPDATE, {
    user: userId,
    asset: [values]
  });
}
export function updateContactDetail() {
  return Axios.patch(ENTITY_PERSON_CONTACTINFO_UPDATE + userId);
}

export function getListPerson() {
  return Axios.get(
    ENTITY_PERSONAL_LIST + "?service_instance=" + service_instanceID
  );
}
export function getEduList() {
  return Axios.get(ENTITY_PERSON_EDUCATION_LIST);
}
export function getFamilyList(id) {
  return Axios.get(ENTITY_PERSON_FAMILY_LIST + "?user=" + id);
}
// export function getOccupationList() {
//   return Axios.get(ENTITY_PERSON_OCCUPATION_LIST  );
// }
export function getAssetList(id) {
  return Axios.get(ENTITY_PERSON_ASSET_LIST + "?user=" + id);
}
export function getContactList() {
  return Axios.get(ENTITY_PERSON_CONTACTINFO_LIST);
}

export function getListPersonByRadioId(id) {
  return Axios.get(ENTITY_PERSONAL_INFO_LIST_BY_ID + id);
}
export function getEduListByRadioId(id) {
  return Axios.get(ENTITY_PERSON_EDUCATION_LIST_BY_ID + id);
}
export function getFamilyListByRadioId(id) {
  return Axios.get(ENTITY_PERSON_FAMILY_LIST_BY_ID + "?id=" + id);
}
// export function getOccupationListByRadioId(id) {
//   return Axios.get(ENTITY_PERSON_OCCUPATION_LIST_BY_ID + id );
// }
export function getAssetListByRadioId(id) {
  return Axios.get(ENTITY_PERSON_ASSET_LIST_BY_ID + id);
}
export function getContactListByRadioId(id) {
  return Axios.get(ENTITY_PERSON_CONTACTINFO_LIST_BY_ID + id);
}

export function getStuProfileDetailListByUserId(id) {
  return Axios.get(STU_PROFILE_DETAIL_USER + id);
}
export function getStuEducationDetailListByUserId(id) {
  return Axios.get(STU_EDUCATION_DETAIL_USER + id);
}
export function getStuFamilyDetailListByUserId(id) {
  return Axios.get(STU_FAMILY_DETAIL_USER + id);
}
export function getStuPropertyDetailListByUserId(id) {
  return Axios.get(STU_PROPERTY_DETAIL_USER + id);
}
export function getStuScholarshipDetailListByUserId(id) {
  return Axios.get(STU_SCOLARSHIP_DETAIL_USER + id);
}

// student education
export function createStuEducationDetails(values) {
  // return Axios.post( STU_EDUCATION_DETAIL,values)
  // return Axios.post( STU_EDUCATION_DETAIL,{
  //   institution_type:instituteType,
  //   qualification:values.qualification,
  //   department:values. department,
  //   year:values. year,
  //   institution_name:values. institution_name,
  //   medium:values. medium,
  //   status:values. status,
  //   percentage:values. percentage,
  //   address_line1:values. address_line1,
  //   address_line2:values. address_line2,
  //   city:values. city,
  //   taluk:values. taluk,
  //   district:values. district,
  //   country_code:fetchCountryId,
  //   phn_nbr:values. phn_nbr[0],
  //   user: id,
  // });
}
export function getStuEducationDetailList() {
  return Axios.get(STU_EDUCATION_DETAIL);
}
export function getStuEducationDetailListById(id) {
  return Axios.get(STU_EDUCATION_DETAIL + id + "/");
}

export function UpdateStuEducationDetail(values) {
  return Axios.post(STU_EDUCATION_DETAIL, values);
  // return Axios.patch(STU_EDUCATION_DETAIL + id + "/", {
  //   institution_type:values.institution_type,
  //   qualification:values.qualification,
  //   department:values. department,
  //   year:values. year,
  //   institution_name:values. institution_name,
  //   medium:values. medium,
  //   status:values. status,
  //   percentage:values. percentage,
  //   address_line1:values. address_line1,
  //   address_line2:values. address_line2,
  //   city:values. city,
  //   taluk:values. taluk,
  //   district:values. district,
  //   country_code:fetchCountryId,
  //   phn_nbr:values. phn_nbr[0],
  //   // user: userid,
  // });
}
export function removeStuEducationDetail(id) {
  return Axios.delete(STU_EDUCATION_DETAIL + id + "/");
}

// student family
export function createStuFamilyDetails(values) {
  return Axios.post(STU_FAMILY_DETAIL, values);
}
export function getStuFamilyDetailList() {
  return Axios.get(STU_FAMILY_DETAIL);
}
export function getStuFamilyDetailListById(id) {
  return Axios.get(STU_FAMILY_DETAIL + id + "/");
}

export function UpdateStuFamilyDetail(id, values) {
  return Axios.patch(STU_FAMILY_DETAIL + id + "/", values);
}
export function removeStuFamilyDetail(id) {
  return Axios.delete(STU_FAMILY_DETAIL + id + "/");
}

// student profile

export function createStuProfileDetails(values) {
  return Axios.post(STU_PROFILE_DETAIL, values);
}
export function getStuProfileDetailList() {
  return Axios.get(STU_PROFILE_DETAIL);
}
export function getStuProfileDetailListById(id) {
  return Axios.get(STU_PROFILE_DETAIL + id + "/");
}

export function UpdateStuProfileDetail(id, values) {
  return Axios.patch(STU_PROFILE_DETAIL + id + "/", values);
}
export function removeStuProfileDetail(id) {
  return Axios.delete(STU_PROFILE_DETAIL + id + "/");
}

// student property

export function createStuPropertyDetails(values) {
  return Axios.post(STU_PROPERTY_DETAIL, values);
}
export function getStuPropertyDetailList() {
  return Axios.get(STU_PROPERTY_DETAIL);
}
export function getStuPropertyDetailListById(id) {
  return Axios.get(STU_PROPERTY_DETAIL + id + "/");
}

export function UpdateStuPropertyDetail(id, values) {
  return Axios.patch(STU_PROPERTY_DETAIL + id + "/", values);
}
export function removeStuPropertyDetail(id) {
  return Axios.delete(STU_PROPERTY_DETAIL + id + "/");
}

// student scholarship
export function createStuScholarshipDetails(values) {
  return Axios.post(STU_SCHOLARSHIP, values);
}
export function getStuScholarshipDetailList() {
  return Axios.get(STU_SCHOLARSHIP);
}
export function getStuScholarshipDetailListById(id) {
  return Axios.get(STU_SCHOLARSHIP + id + "/");
}

export function UpdateStuScholarshipDetail(id, values) {
  return Axios.patch(STU_SCHOLARSHIP + id + "/", values);
}
export function removeStuScholarshipDetail(id) {
  return Axios.delete(STU_SCHOLARSHIP + id + "/");
}

export function resendVerificationEmail(data) {
  return Axios.post(ORG_AUTH_RESEND_VERIFICATION_EMAIL, data);
}

export function getUserDetailList(node_id, currentPage) {
  return Axios.get(ORG_USER_USER_DETAIL_LIST + "?node_id=" + node_id);
}

export function createProfileImage(id) {
  return Axios.post(ORG_USER_PROFILE_IMG_ADD + id);
}

export function userPhnNumVerify(phn_nbr, OTP) {
  return Axios.post(ORG_USER_ADDPHNNUM_VERIFY, { phn_nbr: phn_nbr, code: OTP });
}

export function resendOtp(phn_nbr, firm) {
  return Axios.post(ORG_AUTH_RESEND_OTP, {
    phn_nbr: phn_nbr,
    firm: parseInt(firm)
  });
}

export function resendMobileOtp(data) {
  return Axios.post(ORG_AUTH_RESEND_OTP, data);
}

export function verificationInprogress(code) {
  return Axios.get(ORG_AUTH_VERIFICATION_INPROGRESS + code);
}

export function createProfileImg(image) {
  return Axios.post(ORG_USER_PROFILE_IMG_ADD_WITHOUT_ID, {
    image: image
  });
}

export function createEmail(email) {
  return Axios.post(ORG_USER_EMAIL_ADD, {
    email: email
  });
}

export function updateEmail(data) {
  return Axios.post(ORG_USER_EMAIL_ADD, data);
}
export function createPhnNbr(phn_nbr, country_id) {
  return Axios.post(ORG_USER_PHNNUM_ADD, {
    phn_nbr: phn_nbr[0],
    country_id: country_id
  });
}
export function updatePhnNbr(data) {
  return Axios.post(ORG_USER_PHNNUM_UPDATE, data);
}
export function getCountryPg() {
  return Axios.get(GET_COUNTRY_BY_PAGE);
}
export function getCountyList(id) {
  return Axios.get(GET_COUNTRY + id);
}

export function getStateList(id) {
  return Axios.get(GET_STATE + id);
}
export function getCityList(countryId, stateId, countyId) {
  return Axios.get(
    GET_CITY + countryId + "&state-id=" + stateId
  );
}

export function updateUserAddInfo(id, value) {
  return Axios.patch(ORG_USER_ADDRESS_UPDATE + id + "/", value);
}
export function createUserAddInfo(value) {
  return Axios.post(ORG_USER_ADDRESS_CREATE, value);
}
export function updatePassword(value) {
  return Axios.patch(ORG_AUTH_CHANGE_PASSWORD, value);
}

export function updateUserPersonalInfo(id, value) {
  return Axios.patch(ORG_USER_PERSONAL_INFO_UPDATE + "?id=" + id, value);
}

// get users list with pagination

export function getUserList(page) {
  return Axios.get(GET_USER_LIST + page);
}

export function getAllUserList(id) {
  return Axios.get(ORG_USER_GET_USER_DETAIL + id + "/");
}

// get single user
export function getSingleUser(id) {
  return Axios.get(ORG_USER_PRIVATE_SIGNUP + id + "/");
}

// add new user
export function postUser(values) {
  return Axios.create(defaultOptions).post(ORG_USER_PRIVATE_SIGNUP, values);
}

// update a user
export function patchUser(id, values) {
  return Axios.patch(ORG_USER_PRIVATE_SIGNUP + id + "/", values);
}

// delete user
export function deleteUser(id) {
  return Axios.create(defaultOptions).delete(ORG_USER_REMOVE + id + "/");
}

export const HTTP_CODE = {
  ACCEPTED: 202,
  BADREQUEST: 400,
  CONFLICT: 409,
  CREATED: 201,
  OK: 200
};

export const ENTITY_EVENT_ADD = "/Entity/Event/Add";

export const ENTITY_EVENT_GET = "/Entity/Event/Get";

export const ENTITY_EVENT_LIST = "/Entity/Event/List";
export const ENITITY_EVENT_EVENTINFO_UPDATE = "/Entity/Event/EventInfo/Update";

export function getEntityEventGet() {
  return Axios.get(ENTITY_EVENT_GET + "?id=1");
}
export function getEntityList() {
  return Axios.get(ENTITY_EVENT_LIST);
}
export function patchEntityEventInfoUpdate(values) {
  return Axios.patch(ENITITY_EVENT_EVENTINFO_UPDATE, values);
}

export const ENTITY_PERSON_PERSONALINFO_UPDATE =
  "/Entity/Person/PersonalInfo/Update";

export function updatePersonalInfo(data) {
  return Axios.patch(ENTITY_PERSON_PERSONALINFO_UPDATE, data);
}

export const ENTITY_PERSON_GET = "/Entity/Person/Get/";
export function getPersonDetails(id) {
  return Axios.get(ENTITY_PERSON_GET + "?user=" + id);
}

// Education Course List
export const ENTITY_PERSON_EDUCATION_LISTCOURSETYPE =
  "/Entity/Education/ListCourseTypes";
export function getEducationListCourseType() {
  return Axios.get(ENTITY_PERSON_EDUCATION_LISTCOURSETYPE + "?service_instance=" + service_instanceID);
}

// Education ListMediums List
export const ENTITY_EDUCATION_LISTMEDIUMS = "Entity/Education/ListMediums";
export function getEducationListMediums() {
  return Axios.get(ENTITY_EDUCATION_LISTMEDIUMS);
}

// Education ListMediums List
export const ENTITY_EDUCATION_PERSON_LISTSPECIALIZATIONS = "Entity/Person/ListSpecializations"
export function getEducationListSpecializations(id) {
  return Axios.get(ENTITY_EDUCATION_PERSON_LISTSPECIALIZATIONS + "?service_instance=" + id);
}

export const ENTITY_PERSON_EDUCATION_REMOVE =
  "Entity/Person/Education/Record/Remove";
export function deleteEducationDetails(data) {
  return Axios.delete(ENTITY_PERSON_EDUCATION_REMOVE, data);
}

// Get Api Relation List
export const ENTITY_PERSON_LISTRELATIONS = "Entity/Person/ListRelations";
export function getPersonListRelations() {
  return Axios.get(ENTITY_PERSON_LISTRELATIONS);
}

// Delete Api FamilyMember
export const ENTITY_PERSON_FAMILYMEMBER_RECORD_REMOVE = "Entity/Person/FamilyDetails/Record/Remove"
export function deletePersonFamilyMemberRecordRemove(data) {
  return Axios.delete(ENTITY_PERSON_FAMILYMEMBER_RECORD_REMOVE, data);
}

// Delete Api FamilyMember
export const ENTITY_PERSON_PROPERTY_RECORD_REMOVE = "Entity/Person/Asset/Record/Remove"
export function deletePersonPropertyRecordRemove(data) {
  return Axios.delete(ENTITY_PERSON_PROPERTY_RECORD_REMOVE, data);
}

// Delete Api Occupation
export const ENTITY_PERSON_OCCUPATION_RECORD_REMOVE =
  "/Entity/Person/Occupation/Record/Remove";
export function deletePersonOccupationRecordRemove(data) {
  return Axios.delete(ENTITY_PERSON_OCCUPATION_RECORD_REMOVE, data);
}

// Get Api OccupationList
export const ENTITY_PERSON_OCCUPATION_LIST = "/Entity/Person/Occupation/List";
export function getOccupationList(id) {
  return Axios.get(ENTITY_PERSON_OCCUPATION_LIST + "?user=" + id);
}

// Get Api GenderList
export const ENTITY_PERSON_LISTGENDER = "Entity/Person/ListGender";
export function getGenderList() {
  return Axios.get(ENTITY_PERSON_LISTGENDER);
}

// Get Api ListMaritalStates
export const ENTITY_PERSON_LISTMARITALSTATES =
  "Entity/Person/ListMaritalStates";
export function getMaritalList() {
  return Axios.get(ENTITY_PERSON_LISTMARITALSTATES);
}

// Get Api ListOccupation
export const ENTITY_OCCUPATION_LISTTYPE = "/Entity/Occupation/ListTypes";
export function getOccupationlList() {
  return Axios.get(ENTITY_OCCUPATION_LISTTYPE);
}

// Get Api Occupation Record
export const ENTITY_PERSON_OCCUPATION_RECORD_GET =
  "/Entity/Person/Occupation/Record/Get";
export function getOccupationRecord(id) {
  return Axios.get(ENTITY_PERSON_OCCUPATION_RECORD_GET + "?id=" + id);
}

// scholarship Api
export const ENTITY_SCHOLARSHIP_PLAN_GET = "/Entity/Scholarship/Plan/Get";
export const ENTITY_SCHOLARSHIP_PLAN_LIST = "/Entity/Scholarship/Plan/List";
export const ENTITY_SCHOLARSHIP_PLAN_ADD = "/Entity/Scholarship/Plan/Add";
export const ENTITY_SCHOLARSHIP_PLAN_UPDATE = "/Entity/Scholarship/Plan/Update";
export const ENTITY_SCHOLARSHIP_PLAN_REMOVE = "/Entity/Scholarship/Plan/Remove";

export function getEnitySchilarshipPlanGet(id) {
  return Axios.get(ENTITY_SCHOLARSHIP_PLAN_GET + "plan=" + id);
}

export function postEnitySchilarshipPlanAdd(data) {
  return Axios.post(ENTITY_SCHOLARSHIP_PLAN_ADD, data);
}
export function patchEnitySchilarshipPlanUpdate(data) {
  return Axios.patch(ENTITY_SCHOLARSHIP_PLAN_UPDATE, data);
}
export function deleteEnitySchilarshipPlan(id) {
  return Axios.delete(ENTITY_SCHOLARSHIP_PLAN_REMOVE + "?id=" + id);
}

export const ENTITY_EVENT_MEDIA_ADD = "/Entity/Event/Media/Add";
export const ENTITY_EVENT_MEDIA_GET = "/Entity/Event/Media/Get";
export const ENTITY_EVENT_MEDIA_LIST = "/Entity/Event/Media/List";
export const ENTITY_EVENT_MEDIA_REMOVE = "Entity/Event/Media/Remove";

export function deleteEnityEventMediaRemove(data) {
  return Axios.delete(ENTITY_EVENT_MEDIA_REMOVE, data);
}

export const ENTITY_TAGSET_LEST = "/Entity/Tagset/List";

// Get Profession List
export const ENTITY_TAGSET_LIST = "/Entity/Tagset/List";
export function getEntityTagsetList(id) {
  return Axios.get(
    ENTITY_TAGSET_LIST + "?service_instance=" + id + "&entity_type=person"
  );
}

// Get Education List
export const ENTITY_EDUCATION_LISTEDUCATIONTYPES = "Entity/Education/ListEducationTypes";
export function getEntityEducationListType(id) {
  return Axios.get(ENTITY_EDUCATION_LISTEDUCATIONTYPES + "?service_instance=" + id);
}

// Get Country List
export function getCountryList() {
  return Axios.get(GET_COUNTRY_BY_PAGE);
}

export const ENTITY_FORM_QUESTION_ADD = "Entity/Form/Question/Add";
export function postEnityFormQuestionAdd(data) {
  return Axios.post(ENTITY_FORM_QUESTION_ADD, data);
}
export const ENTITY_FORM_INPUT_TYPES_LIST = "/Entity/InputTypes?page_size=1000"
export const ENTITY_FORM_QUESTION_TYPES_LIST = "Entity/Form/QuestionTypes/List";

export function getEnityFormInputTypes() {
  return Axios.get(ENTITY_FORM_INPUT_TYPES_LIST);
}

export const ENTITY_FORM_QUESTION_LIST = "Entity/Form/Question/List";

export const ENTITY_FORM_QUESTION_UPDATE = "Entity/Form/Question/Update";
export function patchEnityFormQuestionUpdate(data) {
  return Axios.patch(ENTITY_FORM_QUESTION_UPDATE, data);
}

export const ENTITY_FORM_QUESTION_GET = "Entity/Form/Question/Get";
export function getEnityFormQuestionGet(id) {
  return Axios.get(ENTITY_FORM_QUESTION_GET + "?question_id=" + id);
}

export const ENTITY_FORM_QUESTION_REMOVE = "Entity/Form/Question/Remove";
export function deleteEnityFormQuestionRemove(data) {
  return Axios.delete(ENTITY_FORM_QUESTION_REMOVE, data);
}

export const API_ENTITY_FORM_QUESTION_UPDATE = "/Entity/Form/Question/Update";
export function EnityFormQuestionUpdate(data) {
  return Axios.patch(API_ENTITY_FORM_QUESTION_UPDATE, data);
}

export const ENTITY_FORM_ANSWER_CHOICE_GET = "Entity/Form/AnswerChoice/Get";

export function getEntityFormAnswerChoiceGet(id) {
  return Axios.get(ENTITY_FORM_ANSWER_CHOICE_GET + "?id=" + id);
}

export const ENTITY_FORM_ANSWER_CHOICE_ADD = "Entity/Form/AnswerChoice/Add";
export function postQuestionChoiceAdd(data) {
  return Axios.post(ENTITY_FORM_ANSWER_CHOICE_ADD, data);
}

export const ENTITY_FORM_ANSWER_CHOICE_UPDATE =
  "Entity/Form/AnswerChoice/Update";
export function patchEnityFormAnswerChoiceUpdate(data) {
  return Axios.patch(ENTITY_FORM_ANSWER_CHOICE_UPDATE, data);
}

export const ENTITY_FORM_ANSWER_CHOICE_REMOVE =
  "Entity/Form/AnswerChoice/Remove";

export function deleteEnityFormAnswerChoiceRemove(id) {
  return Axios.delete(ENTITY_FORM_ANSWER_CHOICE_REMOVE, id);
}

export const STORE_ORDER_STATISTICES = "Entity/Order/Statics";

//
export const SCHOLARSHIP_APPLICATION_ADD =
  "/Entity/Scholarship/Application/Add";
export function postScholarshipApplicationAdd(data) {
  return Axios.post(SCHOLARSHIP_APPLICATION_ADD, data);
}

export const SCHOLARSHIP_APPLICATION_STATUS_UPDATE =
  "/Entity/Scholarship/Application/ApplicationStatusUpdate";
export function ScholarshipApplicationStatusUpdate(data) {
  return Axios.patch(SCHOLARSHIP_APPLICATION_STATUS_UPDATE, data);
}

export const SCHOLARSHIP_APPLICATION_REMOVE =
  "/Entity/Scholarship/Application/Remove";
export function deleteScholarshipApplicationRemove(id) {
  return Axios.delete(SCHOLARSHIP_APPLICATION_REMOVE, id);
}

export const API_ENTITY_SCHOLARSHIP_LIST =
  "/Entity/Scholarship/Application/List";
export const API_ENTITY_DONATION_LIST = "Entity/Donation/ServiceInstance/List";

export const API_ENTITY_PERSON_GET = "Entity/Person/Get/";
export const API_ENTITY_SCHOLARSHIP_APPLICATION_GET =
  "/Entity/Scholarship/Application/Get";

export const API_ADD_ENTITY_FORM_ANSWER = "/Entity/Form/Answer/Add";
export function postFormAnswerAdd(data) {
  return Axios.post(API_ADD_ENTITY_FORM_ANSWER, data);
}

export const API_DELETE_ENTITY_FORM_ANSWER = "/Entity/Form/Answer/Remove";
export function deleteFormAnswerAdd(id) {
  return Axios.delete(API_DELETE_ENTITY_FORM_ANSWER + "?id=" + id);
}

export const API_UPDATE_ENTITY_FORM_ANSWER = "/Entity/Form/Answer/Update";
export function patchFormAnswerUpdate(data) {
  return Axios.patch(API_UPDATE_ENTITY_FORM_ANSWER, data);
}

// Profession Sectors List
export const ENTITY_OCCUPATION_PROFESSIONSECTORS =
  "Entity/Occupation/ProfessionSectors";

export function getProfessionSectors() {
  return Axios.get(ENTITY_OCCUPATION_PROFESSIONSECTORS + "?service_instance=" + service_instanceID);
}

// Profession List
export const ENTITY_OCCUPATION_PROFESSION = "Entity/Occupation/Profession";

export function getOccupationProfession() {
  return Axios.get(ENTITY_OCCUPATION_PROFESSION + "?service_instance=" + service_instanceID);
}

//aprroved status
export const APPLICATION_APPROVE_STATUS =
  "/Entity/Scholarship/Application/ApproveStatusUpdate";

export function UpdateApplicationStatus(data) {
  return Axios.patch(APPLICATION_APPROVE_STATUS, data);
}
// Education Status List
export const ENTITY_EDUCATION_STATUS_LIST = "Entity/Education/Status/List";

export function getEducationStatusList() {
  return Axios.get(ENTITY_EDUCATION_STATUS_LIST);
}

// Family Occupation List
export const ENTITY_FAMILYDETAILS_OCCUPATION =
  "Entity/FamilyDetails/Occupation";

export function getFamilyDetailsOccupation(id) {
  return Axios.get(ENTITY_FAMILYDETAILS_OCCUPATION + "?service_instance=" + id);
}

// Property Type List
export const ENTITY_PERSON_ASSET_LISTTYPES = "Entity/Person/Asset/ListTypes";

export function getPersonAssetListTypes(id) {
  return Axios.get(ENTITY_PERSON_ASSET_LISTTYPES + "?service_instance=" + id);
}

// Property Unit List
export const ENTITY_PERSON_ASSET_UNITTYPES = "Entity/Person/Asset/UnitTypes";

export function getPersonAssetUnitTypes() {
  return Axios.get(ENTITY_PERSON_ASSET_UNITTYPES);
}

// Get Auth by Token
export const ORG_AUTH_GETBYTOKEN = "Org/Auth/GetByToken/";

export function getAuthByToken() {
  return Axios.get(ORG_AUTH_GETBYTOKEN);
}

// Get Ambition List
export const ENTITY_PERSON_AMBITION_LIST = "Entity/Person/Ambition/List";

export function getAmbitionList() {
  return Axios.get(ENTITY_PERSON_AMBITION_LIST);
}

// Get Service Instance List
export const ORG_SERVICES_SERVICEINSTANCE_LIST =
  "Org/Services/ServiceInstances/List/";

export function gerServiceInstanceList() {
  return Axios.get(ORG_SERVICES_SERVICEINSTANCE_LIST);
}

// User Status UpdateApi
export const ORG_USER_UPDATE_REGISTRATION_STATUS =
  "Org/User/UpdateRegistrationStatus/";

export function updateUserRegistrationStatus(data, id) {
  return Axios.patch(ORG_USER_UPDATE_REGISTRATION_STATUS + "?id=" + id, data);
}

// Role DeleteApi
export const ORG_ROLES_REMOVE = "/Org/Roles/Remove/";
export function deleteRole(id) {
  return Axios.create(defaultOptions).delete(ORG_ROLES_REMOVE + id + "/");
}

// Role Group List
export const ORG_ROLES_GROUP_LIST = "/Org/Roles/Group/List/";
export function getRoleGroupList() {
  return Axios.create(defaultOptions).get(ORG_ROLES_GROUP_LIST);
}

// Role GetApi
export const ORG_ROLES_GETROLEDETAILS = "/Org/Roles/GetRoleDetails/";
export function getRole(id) {
  return Axios.create(defaultOptions).get(ORG_ROLES_GETROLEDETAILS + id + "/");
}

// Role UpdateApi
export const ORG_ROLES_UPDATE = "/Org/Roles/Update/";
export function updateRole(data, id) {
  return Axios.create(defaultOptions).patch(ORG_ROLES_UPDATE + id + "/", data);
}

// Role CreateApi
export const ORG_ROLES_CREATE = "/Org/Roles/Add/";
export function createRole(data, id) {
  return Axios.create(defaultOptions).post(ORG_ROLES_CREATE, data);
}

// Get User List
export function getUserLists() {
  return Axios.create(defaultOptions).get(
    ORG_USER_USER_DETAIL_LIST + "?service_instance_id=" + org_instance
  );
}

// Get Role List
export function getRoleLists() {
  return Axios.create(defaultOptions).get(
    ORG_ROLES_LIST + "?service_instance_id=" + org_instance
  );
}

// Add Post Api
export const ORG_POST_ADD = "/Org/Posts/Add/";
export function createPost(data) {
  return Axios.create(defaultOptions).post(ORG_POST_ADD, data);
}

// Post GetApi
export const ORG_POST_GET = "/Org/Posts/Get/";
export function getPost(id) {
  return Axios.create(defaultOptions).get(ORG_POST_GET + id + "/");
}

// Post List GetApi
export function getPostList() {
  return Axios.create(defaultOptions).get(ORG_POST_LIST + "?org_instance_id=" + service_instanceID);
}

// Post UpdateApi
export const ORG_POST_UPDATE = "/Org/Posts/Update/";
export function updatePost(data, id) {
  return Axios.create(defaultOptions).patch(ORG_POST_UPDATE + id + "/", data);
}

// Role DeleteApi
export const ORG_POSTS_REMOVE = "/Org/Posts/Remove/";
export function deletePost(id) {
  return Axios.create(defaultOptions).delete(ORG_POSTS_REMOVE + id + "/");
}

// Role Service List Get
export const ORG_ROLES_SERVICE_LIST = "/Org/Roles/Service/List/";
export function getRoleServiceList(id) {
  return Axios.create(defaultOptions).get(
    ORG_ROLES_SERVICE_LIST + "?service_instance_id=" + id
  );
}

// Assign Role Add Api
export const ORG_ROLES_SERVICE_INSTANCE_ADD =
  "/Org/Roles/Service/Instance/Add/";
export function createAssignRole(data) {
  return Axios.create(defaultOptions).post(
    ORG_ROLES_SERVICE_INSTANCE_ADD,
    data
  );
}

// Assign Role Add Api
export const ORG_ROLES_SERVICE_INSTANCE_GET =
  "/Org/Roles/Service/Instance/Get/";
export function getServiceInstanceRole(id) {
  return Axios.create(defaultOptions).get(
    ORG_ROLES_SERVICE_INSTANCE_GET + id + "/"
  );
}

//
export const ENTITY_SCHOLARSHIP_APPLICATION_STATISTICS_GET =
  "Entity/Scholarship/Application/Statistics/Get";
export function getEntityScholarshipApplicationStatisticsGet() {
  return Axios.get(ENTITY_SCHOLARSHIP_APPLICATION_STATISTICS_GET + "?service_instance=" + service_instanceID);
}

//Dhasboard
export const ENTITY_PERSON_STATISTICS_GET = "/Entity/Person/Statistics/Get?";
export function getEntityPersonStatisticsGet() {
  return Axios.get(ENTITY_PERSON_STATISTICS_GET + "service_instance=" + service_instanceID);
}

// Vountering Type List
export const ENTITY_PERSON_LISTVOLUNTEERING_TYPE = "/Entity/Person/ListVolunteeringType";
export function getVolunteringType(id) {
  return Axios.get(ENTITY_PERSON_LISTVOLUNTEERING_TYPE + "?service_instance=" + service_instanceID);
}

// Dropdown Types
export const ENTITY_ATTRIBUTE_CHOICES_TYPES = "/Entity/Attribute/Choices/Types";
export function getAttributeChoicesType() {
  return Axios.get(ENTITY_ATTRIBUTE_CHOICES_TYPES);
}

// Dropdown List
export const ENTITY_ATTRIBUTE_CHOICES_LIST = "Entity/Attribute/Choices/List";
export function getAttributeChoicesList(id) {
  return Axios.get(
    ENTITY_ATTRIBUTE_CHOICES_LIST +
    "?service_instance=" +
    localStorage.getItem("service-instance-id") +
    "&category_type=" +
    id
  );
}

// Dropdown Choices Add
export const ENTITY_ATTRIBUTE_CHOICES_ADD = "/Entity/Attribute/Choices/Add";
export function getAttributeChoicesAdd(data) {
  return Axios.post(ENTITY_ATTRIBUTE_CHOICES_ADD, data);
}

// Dropdown Choices Delete
export const ENTITY_ATTRIBUTE_CHOICES_REMOVE =
  "/Entity/Attribute/Choices/Remove";
export function deleteChoices(id) {
  return Axios.delete(ENTITY_ATTRIBUTE_CHOICES_REMOVE + "?id=" + id);
}

//Report Api
export const API_ENTITY_PERSON_FILTERPARAMS_TYPE =
  "/Entity/Person/FilterParams/List?";
export function ApiReportsFilterParams() {
  return Axios.get(API_ENTITY_PERSON_FILTERPARAMS_TYPE);
}

// Refresh Token
export const ORG_AUTH_REFRESH = "/Org/Auth/Refresh/";
export function refreshAccessToken(data) {
  return Axios.post(ORG_AUTH_REFRESH, data);
}

// Tagset APi Section start
// Tagset List
export const API_ENTITY_TAGSET_WIDGETRECORD_LIST = "Entity/Tagset/WidgetRecord/List";
export function apiEntityTagsetWidgetrecordList(id) {
  return Axios.get(API_ENTITY_TAGSET_WIDGETRECORD_LIST + "?service_instance=" + id);
}

//Tagset config add
export const API_ENTITY_TAGSET_CONFIG_ADD = "Entity/Config/Tagset/Add";
export function apiEntityTagsetConfigAdd(data) {
  return Axios.post(API_ENTITY_TAGSET_CONFIG_ADD + data);
}

//Tagset Update
export const API_ENTITY_WIDGET_TAGSET_UPDATE = "/Entity/Tagset/WidgetRecord/Update/";
export function apiEntityWidgetTagsetUpdate(id, value) {
  return Axios.patch(API_ENTITY_WIDGET_TAGSET_UPDATE + id, value);
}

//Tagset Delete
export const API_ENTITY_TAGSET_DELETE = "/Entity/Tagset/WidgetRecord/Remove?id=";
export function apiEntityTagsetRemove(id) {
  return Axios.delete(API_ENTITY_TAGSET_DELETE + id);
}

//Tags API
//Tags list
export const API_WIDGETRECORD_TAGS_LIST = "/Entity/Tag/WidgetRecord/List?page_size=1000&tagset=";
export function apiWidgetRecordTagsList(id) {
  return Axios.get(API_WIDGETRECORD_TAGS_LIST + id);
}
//tgs get
export const API_WIDGETRECORD_TAGS_GET = "/Entity/Tag/WidgetRecord/Get?id=";
export function apiWidgetRecordTagsGet(id) {
  return Axios.get(API_WIDGETRECORD_TAGS_GET + id);
}
//Tags Update
export const API_ENTITY_TAGS_UPDATE = "/Entity/Tag/WidgetRecord/Update/";
export function apiEntityTagsUpdate(id, value) {
  return Axios.patch(API_ENTITY_TAGS_UPDATE + id, value);
}
//Tags Add
export const API_WIDGETRECORD_TAGS_ADD = "/Entity/Tag/WidgetRecord/Add";
export function apiWidgetRecordTagsAdd() {
  return Axios.get(API_WIDGETRECORD_TAGS_ADD);
}

//Tagset Delete
export const API_ENTITY_TAGS_DELETE = "/Entity/Tag/WidgetRecord/Remove";
export function apiEntityTagsRemove(id) {
  return Axios.delete(API_ENTITY_TAGS_DELETE + id);
}

//Tagchoise API
//Tagchoise List
export const API_WIDGETRECORD_TAGCHOICE_LIST = "/Entity/TagChoice/WidgetRecord/List?tag_id=";
export function apiWidgetRecordTagChoiceList(id) {
  return Axios.get(API_WIDGETRECORD_TAGCHOICE_LIST + id);
}

//TagChoice Add
export const API_WIDGETRECORD_TAGCHOICE_ADD = "/Entity/TagChoice/WidgetRecord/Add";
export function apiWidgetRecordTagChoiceAdd() {
  return Axios.get(API_WIDGETRECORD_TAGS_ADD);
}

//TagChoice Get
export const API_WIDGETRECORD_TAGCHOICE_GET = "/Entity/TagChoice/WidgetRecord/Get?id=";
export function apiEntityTagsetWidgetrecordGet(id) {
  return Axios.get(API_WIDGETRECORD_TAGCHOICE_GET + id);
}

//TagChoice Update
export const API_WIDGETRECORD_TAGCHOICE_UPDATE = "/Entity/TagChoice/WidgetRecord/Update/";
export function apiEntityTagsetWidgetrecordUpdate(id, value) {
  return Axios.patch(API_WIDGETRECORD_TAGCHOICE_UPDATE + id, value);
}
//TagChoice Delete
export const API_ENTITY_TAGCHOICE_DELETE = "/Entity/TagChoice/WidgetRecord/Remove";
export function apiEntityTagChoiceRemove() {
  return Axios.delete(API_ENTITY_TAGCHOICE_DELETE);
}

//TagChoice/Tag/Add
export const API_ENTITY_TAGCHOICE_TAG_ADD = "Entity/TagChoice/Tag/Add";
export function apiEntityTagChoiceTagAdd() {
  return Axios.get(API_ENTITY_TAGCHOICE_TAG_ADD);
}
//Entity Types
export const API_ENTITY_ENTITY_LIST = "Entity/EntityTypes";

export function apiEntityEntityList() {
  return Axios.get(API_ENTITY_ENTITY_LIST);
}

//Entity/WidgetTypes

export const API_ENTITY_WIDGET_LIST = "Entity/WidgetTypes";

export function apiEntityWidgetTypesList(id) {
  return Axios.get(API_ENTITY_WIDGET_LIST + "?entity_type=" + id);
}

//Entity/WidgetTypes

export const API_ENTITY_TAGSET_WIDGET_GET = "/Entity/Tagset/WidgetRecord/Get";

export function apiEntityWidgetTagSetGet(id) {
  return Axios.get(API_ENTITY_TAGSET_WIDGET_GET + "?id=" + id);
}

//ConfirmationStatus
export const API_CONFIRMATION_STATUS = "/Entity/ConfirmationStatus";

export function apiConfirmationStatus() {
  return Axios.get(API_CONFIRMATION_STATUS);
}




// node setting api start 

export const ORG_TREE_NODE_ADDRESS_CREATE = "/Org/Tree/NodeAddress/Create/"
export function postOrgTreeNodeAddressCreate(id, data) {
  return Axios.post(ORG_TREE_NODE_ADDRESS_CREATE + id, data);
}

export const ORG_TREE_NODE_ADDRESS_UPDATE = "/Org/Tree/NodeAddress/Update/"

export function patchOrgTreeNodeAddressUpdate(id, data) {
  return Axios.patch(ORG_TREE_NODE_ADDRESS_UPDATE + id, data);
}

// node setting api end 


//Honours
export const API_LIST_HONOURS = "/Entity/Education/ListHonours";
export function apiListHonours() {
  return Axios.get(API_LIST_HONOURS);
}

//Country,state API with search
export const API_SEARCH_PLACE_LIST = "/Org/PlacesList?";
export function apiPlaceSearch(type, value) {
  return Axios.get(API_SEARCH_PLACE_LIST + "type=" + type + "&value=" + value);
}

// Donation Dasboard
export const API_ENTITY_DONATION_STATISTICSOVERVIEW = "/Entity/Donation/StatisticsOverview";
export function apiEntityDonationStatisticOverview() {
  return Axios.get(API_ENTITY_DONATION_STATISTICSOVERVIEW + "?service_instance=" + org_instance);
}

//List DataTypes
export const API_ENTITY_LIST_DATATYPES = "/Entity/ListDatatypes";
export function apiEntityListDataTypes() {
  return Axios.get(API_ENTITY_LIST_DATATYPES);
}

//Blood group list
export const API_LIST_BLOOD_GROUPS = "/Entity/BloodGroup/Types";
export function apiListBloodGroups() {
  return Axios.get(API_LIST_BLOOD_GROUPS);
}

//Entity Tagset Add
export const API_ENTITY_TAGSET_ADD = "Entity/Tagset/Add"
export function ApiEntityTagsetAdd(data) {
  return Axios.post(API_ENTITY_TAGSET_ADD + data);
}

//Entity Tagset Delete
export const API_ENTITY_TAGSET_REMOVE = "Entity/Tagset/Remove";
export function apiEntityTagSetRemove() {
  return Axios.delete(API_ENTITY_TAGSET_REMOVE);
}

//Entity tagset get
export const API_ENTITY_TAGSET_GET = "Entity/Tagset/Get?id="
export function apiEntityTagsetGet(id) {
  return Axios.get(API_ENTITY_TAGSET_GET + id);
}

//Entity tagset Update
export const API_ENTITY_TAGSET_UPDATE = "Entity/Tagset/Update/"
export function apiEntityTagsetUpdate(id, data) {
  return Axios.patch(API_ENTITY_TAGSET_UPDATE + id, data);
}

//Entity Tagvalue Remove
export const API_ENTITY_TAGVALUE_REMOVE = "Entity/TagValues/WidgetRecord/Remove/";
export function apiEntityTagValueRemove(id) {
  return Axios.delete(API_ENTITY_TAGVALUE_REMOVE + id + "/");
}


export const ORG_USER_PRIMARY_PHONENBR_ACTIVATE = "/Org/User/PhoneNumber/Add/"

export function OrgUserPrimaryPhoneNbrActivate(data) {
  return Axios.post(ORG_USER_PRIMARY_PHONENBR_ACTIVATE, data);
}
export const ORG_USER_PRIMARY_EMAIL_ACTIVATE = "Org/User/PrimaryEmail/Activate/"

export function OrgUserPrimaryEmailActivate(data) {
  return Axios.post(ORG_USER_PRIMARY_EMAIL_ACTIVATE, data);
}


export function OrgUserProfileAdd(data){
  return Axios.post(ORG_USER_PROFILE_IMG_ADD_WITHOUT_ID,data)
}
export const ORG_USER_PROFILE_IMG_DELETE = "Org/User/ProfileImg/Delete/"

export function OrgUserProfileImagDelete(id){
  return Axios.delete(ORG_USER_PROFILE_IMG_DELETE+id+"/")
}